export default {
	// 获取页面数据
	getTableData() {
		
		this.onLoad(true)
		var params = {
			
			page: this.$refs.Page.pagination.current,
			limit: this.$refs.Page.pagination.pageSize
		}

		if(this.id) {
			params.type = this.id
		}

		if(this.search) {
			params.val = this.search
		}

		this.axiosGets("Admin/list", params, function(res) {

			this.tableData = res.data.data.data;
		this.onLoad(false)
			//分页
			this.total = res.data.data.total
			console.log(this.total);
		})
	},

	//添加
	add() {
		// this.history('/admin/edit')
		this.$refs.Edit.isShow = true;
	},

	//编辑
	edit(scope) {
		this.$refs.Edit.form.id = scope.id;
		this.$refs.Edit.form.mobile = scope.mobile;
		this.$refs.Edit.form.name = scope.name;
		if(scope.account_type) {
			this.$refs.Edit.form.account_type_id = scope.account_type.id;
			this.$refs.Edit.getClient(scope.account_type.id)
		}
		if(scope.client) {
			this.$refs.Edit.form.client_id = scope.client.id;
			this.$refs.Edit.getClient1(scope.client.id)
		}
		
		this.$refs.Edit.form.group_id = scope.group.id;

		if(scope.role) {
			this.$refs.Edit.form.role_id = scope.role.id;
		}
		//role_id
		// this.$refs.Edit.form.id = scope.id;
		

		this.$refs.Edit.isShow = true;
	},

	//关闭账号
	isState(scope){
		// this.$confirm('此操作将使该账号无法登陆, 是否继续?', '提示', {
		// 	confirmButtonText: '确定',
		// 	cancelButtonText: '取消',
		// 	type: 'warning'
		// }).then(() => {
			this.setStatus(scope.id,scope.is_binding);
		// }).catch(() => {
		// 	this.$message({
		// 		type: 'info',
		// 		message: '已取消删除'
		// 	});
		// });
	},

	setStatus(id,status) {
		let params = {
			id:id,
			is_binding:status// == 1 ? 2 : 1
		}
		this.axiosPost('Admin/editor', params , res => {
			if(res.data.code === 200) {
				this.msgSuccess(res.data.message);
				this.getTableData();
				this.isShow = false;
			}
		})
	},


	//删除
	remove(scope) {
		this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {

			this.sureRemove(scope.id);
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},

	//确定删除
	sureRemove(id) {

		this.axiosGets("Admin/delete?id=" + id, {}, function(res) {
			if (res.data.code === 200) {
				this.getTableData();
				this.msgSuccess(res.data.message)
			} else {
				this.msgError(res.data.message)
			}
		})
	},

	//删除
	unbind(scope) {
		this.$confirm('确定解除绑定?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {

			this.sureRemove1(scope.id);
		}).catch(() => {
			this.$message({
				type: 'info',
				message: '已取消删除'
			});
		});
	},

	//确定删除
	sureRemove1(id) {

		this.axiosGets("Admin/unbind?id=" + id, {}, function(res) {
			if (res.data.code === 200) {
				this.getTableData();
				this.msgSuccess(res.data.message)
			} else {
				this.msgError(res.data.message)
			}
		})
	}
}
