<template>
	<div class="p_20 indexBanner">
		<el-dialog :visible.sync="isShow" :close-on-click-modal="false" @close="closeDialog">
			<!-- <el-divider></el-divider> -->
          <h3 class="text_center title ">账号管理</h3>
			<el-form :model="form" label-width="80px" class="m_t_20">
				<el-form-item label="手机号">
					<el-input v-model="form.mobile" class="w_400"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="form.name" class="w_400"></el-input>
				</el-form-item>
				<el-form-item label="账户类型">
					<el-select v-model="form.account_type_id" @change="getClient">
						<el-option 
							v-for="item in opt"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="客户" v-if="form.account_type_id != 1">
					<el-select v-model="form.client_id"  @change="getClient1">
						<el-option 
							v-for="item in opt1"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="组">
					<el-select v-model="form.group_id">
						<el-option 
							v-for="item in opt2"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="权限">
					<el-select v-model="form.role_id">
						<el-option 
							v-for="item in roleList"
							:key="item.id"
							:value="item.id"
							:label="item.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密码">
					<el-input v-model="form.password" class="w_400" type="password"></el-input>
				</el-form-item>
				<el-form-item label="确认密码">
					<el-input v-model="qrpassword" class="w_400" type="password"></el-input>
				</el-form-item>
				
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isShow: false,
				opt:[],
				opt1:[],
				opt2:[],
				roleList:[],
				form: {
					id:'',
					mobile:'',
					name:'',
					account_type_id:'',
					client_id:'',
					group_id:'',
					role_id:'',
					password:''
				},
				qrpassword:'',

				//图片上传
				imageUrl: '',
				// dialogVisible: false,
				// disabled: false
			}
		},
		mounted(){
			this.getType();
			this.getRoleList();
		},
		methods: {
			
			getRoleList(){
				var params = {
			// id:this.id
					page: 1,
					limit: 1000000
				}

				this.axiosGets("Authority/roleList", params, function (res) {
					if (res.data.code === 200) {
						// this.msgSuccess(res.data.message)
						this.roleList = res.data.data.data;
					}

				})
			},
			
			getType(){
				this.axiosGets('Public/accountTypeList',{}, res => {
					// console.log(res);
					if(res.data.code === 200) {
						this.opt = res.data.data;
					}
					
				})
			},

			getClient(val){
				// console.log(val);
				this.form.client_id = '';
				this.form.group_id = '';
				
				this.opt2 = [];
				if(val != 1){
				this.opt1 = [];
					let  params = {
						account_type_id:val,
						page: 1,
						limit: 10000000
					}
					this.axiosPost("Client/clientList", params, function(res) {
						// console.log(res);
						if(res.data.code === 200) {
							this.opt1 = res.data.data.data;
							// console.log(this.opt1);
						}
					})
					
				}else {
					let  params = {
						client_id:val,
						page: 1,
						limit: 10000000
					}
					this.axiosPost("Group/groupList", params, function(res) {
						// console.log(res);
						if(res.data.code === 200) {
							this.opt2 = res.data.data.data;
						}
					})
				}
			},


			getClient1(val){
				this.form.group_id = '';
				console.log(val);
				this.opt2 = [];
				let  params = {
					client_id:val,
					page: 1,
					limit: 10000000
				}
				this.axiosPost("Group/groupList", params, function(res) {
					// console.log(res);
					if(res.data.code === 200) {
						this.opt2 = res.data.data.data;
					}
				})
					
			},


			//提交
			submit(){
				if(!this.form.account_type_id) {
					this.msgError('请选择客户')
					return;
				}
				if(!this.form.client_id) {
					this.msgError('请选择客户')
					return;
				}
				if(!this.form.group_id) {
					this.msgError('请选择组')
					return;
				}
				if(!this.form.mobile) {
					this.msgError('请填写手机号')
					return;
				}
				if(!this.form.name) {
					this.msgError('请填写姓名')
					return;
				}
				
				
				
				// console.log(this.form.account_type_id);
				if(this.form.account_type_id != 3) {
				// console.log(this.form.account_type_id+'``````````');
					this.form.client_id = this.form.account_type_id;
				}
				// this.form.role_id = 1
				console.log(this.form);
				if(this.form.id) {
					this.edit(this.form);
				}else {
					
					if(!this.form.password) {
						this.msgError('请填写密码')
						return;
					}
					this.add(this.form)
				}
			},
			
			
			//新增
			add(params){
				this.axiosPost('Admin/add', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}
				})
			},
			
			//编辑
			edit(params) {
				if(!params.password){ delete params.password }
				this.axiosPost('Admin/editor', params , res => {
					if(res.data.code === 200) {
						this.msgSuccess(res.data.message);
						this.$parent.getTableData();
						this.isShow = false;
					}
				})
			},


			//图片上传
			// handleAvatarSuccess(file) {
			// 	// console.log(res);
			// 	console.log(file);
				
			// 	//图片id
			// 	// this.form.image_id = res.data.id;
			// 	//图片url
			// 	// this.imageUrl = this.imgURL + res.data.image_url;
			// 	// this.form.url = res.data.image_url;
			// 	// this.dialogVisible = true;
			// },
			
			//关闭窗口 清空数据
			closeDialog(){
				
				this.form = {
					id:'',
					mobile:'',
					name:'',
					account_type_id:'',
					client_id:'',
					group_id:'',
					role_id:'',
					password:''
				}
				this.qrpassword = ''
				this.isShow = false;
			}
		}
	}
</script>

<style scoped>
.title {
  background: #66b1ff;
  margin: 0;
  padding: 10px 0px;
  color: #fff;
}
</style>
