<template>
  <div>
    <div
      class="flex justify_content_between align_items_center content-wrapper p_lr_20 m_b_20"
    >
      <div class="flex align_items_center">
        <h2>账号管理</h2>
        <div class="p_l_20">
          <el-button size="small" type="primary"  v-if="isRole(['Admin/add'])" @click="add" class="m_r_10"
            >新建账号</el-button
          >
        </div>
      </div>
		<div>
			
			<span class="m_r_10">客户类型:</span>
			<el-select v-model="id" @change="getTableData" size="small" class="m_r_20">
				<el-option value="" label="全部"></el-option>
				<el-option :value="1" label="系统"></el-option>
				<el-option :value="2" label="物流"></el-option>
				<el-option :value="3" label="客户"></el-option>
			</el-select>


			<el-input size="small" class="m_r_10 p_l_20 w_250" placeholder="请输入账户名" v-model="search"></el-input>
			<el-button size="small" type="primary" @click="getTableData"
				>搜索</el-button
			>

		</div>
    </div>

    <div class="content-wrapper p_20">
      <!-- -项目列表- -->
      <el-table :data="tableData" width="100%">
        <el-table-column label="创建时间" prop="created_at" width="160px">
          <template slot-scope="scope">
            {{ scope.row.created_at|format }}
          </template>
        </el-table-column>
        <el-table-column label="登录手机号" prop="mobile"></el-table-column>
        <el-table-column label="帐户类型" prop="account_type.name"></el-table-column>
        <el-table-column label="权限" prop="role.name"></el-table-column>
        <el-table-column label="名称" prop="client.name"></el-table-column>
        <el-table-column label="组" prop="group.name"></el-table-column>
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="帐号状态" prop="is_binding">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_binding"
              :active-value="1"
              :inactive-value="2"
              @change="isState(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260" prop="sort">
          <template slot-scope="scope">
            <el-button size="small" v-if="isRole(['Admin/editor'])" @click="edit(scope.row)">编辑</el-button>
            <el-button size="small" v-if="isRole(['Admin/delete'])" @click="remove(scope.row)">删除</el-button>
            <el-button size="small" v-if="isRole(['Admin/unbind'])&&(scope.row.xcx_openid||scope.row.gzh_openid)" @click="unbind(scope.row)">解绑微信</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page ref="Page" :total="total"></page>
      <Edit ref="Edit"></Edit>
    </div>
  </div>
</template>
<script type="text/javascript">
import methods from "./method";
import Edit from "./edit/edit";

export default {
  data() {
    return {
      tableData: [], //列表数据
      total: "", //数据总条数
      id: '',
      search:'',
    };
  },
  methods: methods,
  components: {
    Edit,
  },
  mounted() {
    this.getTableData();
  },
};
</script>
